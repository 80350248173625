import React, { useEffect, useState } from 'react';
import { PopupModel } from '../../models/PopupModel';
import './CreateProductPopup.scss';
import { Button, Form, Input, InputNumber, Modal, Select, Space, Typography, message, Radio } from 'antd';
import { categories, categoriesTitle } from '../../utils/constant';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useGetUser } from '../../utils/useGetUser';
import { addDoc, collection, serverTimestamp, updateDoc, getDocs } from 'firebase/firestore';
import { db, storage } from '../../utils/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import geohash from 'ngeohash';

interface Breed {
  id: string;
  name: string;
}

const useBreeds = () => {
	const [breeds, setBreeds] = useState<Breed[]>([]);
	const [loadingBreeds, setLoadingBreeds] = useState(false);
  
	const fetchBreeds = async () => {
	  setLoadingBreeds(true);
	  try {
		// Changed from 'breeds' to 'DogList'
		const breedsRef = collection(db, 'DogList');
		const querySnapshot = await getDocs(breedsRef);
		const breedsData = querySnapshot.docs.map(doc => ({
		  id: doc.id,
		  name: doc.data().name || '' // Make sure this matches your data structure
		}));
		setBreeds(breedsData);
	  } catch (error) {
		console.error('Error fetching breeds:', error);
		message.error('Failed to load dog breeds');
	  } finally {
		setLoadingBreeds(false);
	  }
	};
  
	useEffect(() => {
	  fetchBreeds();
	}, []);
  
	return { breeds, loadingBreeds };
  };
  
  // Your Select component remains the same
  const BreedSelect = ({ value, onChange }: { value?: string; onChange?: (value: string) => void }) => {
	const { breeds, loadingBreeds } = useBreeds();
  
	return (
	  <Select
		showSearch
		placeholder="Select Dog Breed"
		optionFilterProp="children"
		loading={loadingBreeds}
		value={value}
		onChange={onChange}
		filterOption={(input, option) =>
		  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
		}
		options={breeds.map((breed) => ({
		  value: breed.name,
		  label: breed.name,
		}))}
	  />
	);
  };
interface CreateProductPopupProps extends PopupModel {
	categoryId: number;
}

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

const CreateProductPopup: React.FC<CreateProductPopupProps> = ({ opened, onCancel, onForceCb, categoryId }) => {
	const { user }: any = useGetUser();
	const [previewOpen, setPreviewOpen] = useState<boolean>(false);
	const [previewImage, setPreviewImage] = useState<string>('');
	const [previewTitle, setPreviewTitle] = useState<string>('');
	const [fileList, setFileList] = useState<any>({
		images: [],
		vetChecks: [],
		vaccinationRecords: [],
		pedigreePaperSire: [],
		pedigreePaperDam: [],
		pedigreePaper: [],
		pupvideo: [],
		mumImage: [],
		dadImage: [],
		semenAnalysis: []
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [profilePhotoURLs, setprofilePhotoURLs] = useState<any[]>([]);
	const [vetChecks, setvetChecks] = useState<any[]>([]);
	const [vaccinationRecords, setvaccinationRecords] = useState<any[]>([]);
	const [pupvideo, setPupvideo] = useState<any[]>([]);
	const [mumImage, setMumImage] = useState<any[]>([]);
	const [dadImage, setdadImage] = useState<any[]>([]);
	const [semenAnalysis, setSemenAnalysis] = useState<any[]>([]);
	const [pedigreePaperSire, setpedigreePaperSire] = useState<any[]>([]);
	const [pedigreePaperDam, setpedigreePaperDam] = useState<any[]>([]);
	const [pedigreePaper, setPedigreePaper] = useState<any[]>([]);
	const [ageType, setageType] = useState<string>('');
	// const [selectedCollection, setSelectedCollection] = useState('');
	const [listingCollection, setListingCollection] = useState('');
	// const [dogType] = useState<any[]>([]);
	const [aboutDog] = useState<any[]>([]);
	const [isFrozenAvailable, setIsFrozenAvailable] = useState(null);

	// const [fileDocumentList, setFileDocumentList] = useState<any[]>([]);
	function removeUndefinedProperties(obj: any) {
		for (let prop in obj) {
			if (obj[prop] === undefined) {
				delete obj[prop];
			}
		}
	}
	const normalazeData = (data: any) => {
		try {
		  // Common fields for all collections
		  const baseData = {
			date: serverTimestamp(),
			dynamicLink: data.dynamicLink,
			id: data.id,
			uid: user.uid,
			isPayoutVerified: true,
			isSubscribed: user.isSubscribed,
			geohash: geohash.encode(user.businessLatitude, user.businessLongitude),
			lat: user.businessLatitude,
			lng: user.businessLongitude
		  };
	  
		  // Normalize data based on collection type
		  switch(listingCollection) {
			case categoriesTitle.pupForShowCase: {
			  // Only include required fields for PupForShowCase
			  return {
				...baseData,
				pupImages: profilePhotoURLs.map((item) => item.url),
				pupName: data.pupName,
			  };
			}
	  
			case categoriesTitle.pupProfile: {
			  // Only include required fields for PupProfile
			  return {
				...baseData,
				aboutDog: data.aboutDog,
				ageType: ageType,
				dogAge: data.dogAge,
				dogPrice: data.dogPrice,
				dogType: data.dogType,
				isSold: false,
				location: user.businessAddress || '',
				microChipNumber: data.microChipNumber,
				profilePhotoURLs: profilePhotoURLs.map((item) => item.url),
				sourcenumber: data.sourcenumber
			  };
			}
	  
			case categoriesTitle.studProfile: {
			  // Only include required fields for StudProfile
			  return {
				...baseData,
				bio: data.aboutDog,
				isFrozenAvailable: isFrozenAvailable,
				name: data.name,
				pedigreePaper: pedigreePaper?.length ? pedigreePaper[0].url : '',
				pupsImages: profilePhotoURLs.map((item) => item.url),
				semenAnalysis: semenAnalysis?.length ? semenAnalysis[0].url : '',
				type: data.dogType,
				vaccinationRecords: vaccinationRecords?.length ? vaccinationRecords[0].url : '',
				vetChecks: vetChecks?.length ? vetChecks[0].url : ''
			  };
			}
	  
			case categoriesTitle.upcomingLitters: {
			  // Only include required fields for UpcomingLitters
			  return {
				...baseData,
				dadImage: dadImage?.length ? dadImage[0].url : '',
				description: data.description,
				dogType: data.dogType,
				location: user.businessAddress || '',
				mumImage: mumImage?.length ? mumImage[0].url : '',
				pedigreePaperDam: pedigreePaperDam?.length ? pedigreePaperDam[0].url : '',
				pedigreePaperSire: pedigreePaperSire?.length ? pedigreePaperSire[0].url : ''
			  };
			}
	  
			default:
			  return data;
		  }
		} catch (error) {
		  console.error('Error in normalazeData:', error);
		  return data;
		}
	  };
	  
	useEffect(() => {
		if (categoryId) {
			const tmp = categories.find((item) => item.id === categoryId)?.collection;
			if (tmp) setListingCollection(tmp);
		}
	}, [categoryId]);

	const onCreate = async (values: any) => {
		if (!user) return;
		setIsLoading(true);
		try {
			const selectedCollection = categories.find((item) => item.id === categoryId)?.collection;
			if (typeof selectedCollection !== 'string') return;
	
			// Since normalData will now contain only the fields needed for each collection
			const normalData = normalazeData(values);
			removeUndefinedProperties(normalData);
	
			const docRef = await addDoc(collection(db, selectedCollection), normalData);
	
			// Now, use the returned docRef to update the document with its ID
			await updateDoc(docRef, {
				id: docRef.id
			});
	
			message.success(`Successfully create a product.`);
			setIsLoading(false);
			onCancel();
			onForceCb();
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			message.error(`Something went wrong in creating a product.`);
		}
	};
	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
	};

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ ...pre, images: newFileList }));
	  };
	  
	  const handleRemoveImage = async (file: any) => {
		if (!Array.isArray(fileList) || !fileList.length) return;
		let filteredImagesUrl = profilePhotoURLs.filter((data) => data.id !== file.id);
		let filteredImages = fileList.filter((data) => data.id !== file.id);
		setprofilePhotoURLs(filteredImagesUrl);
		setFileList((pre: any) => ({ ...pre, images: filteredImages }));
	  };
	  
	  const handleChangepedigreePaperSire: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  pedigreePaperSire: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovepedigreePaperSire = async () => {
		setpedigreePaperSire([]);
		setFileList((pre: any) => ({ ...pre, pedigreePaperSire: [] }));
	  };
	  
	  const handleChangepedigreePaperDam: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  pedigreePaperDam: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovepedigreePaperDam = async () => {
		setpedigreePaperDam([]);
		setFileList((pre: any) => ({ ...pre, pedigreePaperDam: [] }));
	  };
	  
	  const handleChangevetChecks: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  vetChecks: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovevetChecks = async () => {
		setvetChecks([]);
		setFileList((pre: any) => ({ ...pre, vetChecks: [] }));
	  };
	  
	  const handleChangevaccinationRecords: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  vaccinationRecords: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovevaccinationRecords = async () => {
		setvaccinationRecords([]);
		setFileList((pre: any) => ({ ...pre, vaccinationRecords: [] }));
	  };
	  
	  const handleChangePupvideo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  pupvideo: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovePupvideo = async () => {
		setPupvideo([]);
		setFileList((pre: any) => ({ ...pre, pupvideo: [] }));
	  };
	  
	  const handleChangemumImage: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  mumImage: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovemumImage = async () => {
		setMumImage([]);
		setFileList((pre: any) => ({ ...pre, mumImage: [] }));
	  };
	  
	  const handleChangedadImage: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  dadImage: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovedadImage = async () => {
		setdadImage([]);
		setFileList((pre: any) => ({ ...pre, dadImage: [] }));
	  };
	  
	  const handleChangesemenAnalysis: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  semenAnalysis: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovesemenAnalysis = async () => {
		setSemenAnalysis([]);
		setFileList((pre: any) => ({ ...pre, semenAnalysis: [] }));
	  };
	  
	  const handleChangePedigreePaper: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setIsUploading(true);
		if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
		  setIsUploading(false);
		}
		if (newFileList.length === 0) {
		  setIsUploading(false);
		}
		setFileList((pre: any) => ({ 
		  ...pre, 
		  pedigreePaper: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
		}));
	  };
	  
	  const handleRemovePedigreePaper = async () => {
		setPedigreePaper([]);
		setFileList((pre: any) => ({ ...pre, pedigreePaper: [] }));
	  };

	// const handleDocumentChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
	// 	setIsUploading(true);
	// 	if (newFileList[newFileList.length - 1].status === 'done') {
	// 		setIsUploading(false);
	// 	}
	// 	setFileDocumentList(newFileList);
	// }
	// const handleRemoveDocument = async (file: any) => {
	// 	if (!Array.isArray(fileDocumentList) || !fileDocumentList.length) return;
	// 	let filteredDocuments = fileDocumentList.filter((data) => data.id !== file.id);
	// 	setFileDocumentList(filteredDocuments);
	// }

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const closeWithReset = () => {
		setPreviewOpen(false);
		setPreviewImage('');
		setPreviewTitle('');
		setFileList([]);
		setprofilePhotoURLs([]);
		setageType('');
	};

	// function handleCollectionChange(
	// 	value: string,
	// 	option: { value: number; label: string; key: string } | { value: number; label: string; key: string }[]
	// ): void {
	// 	throw new Error('Function not implemented.');
	// }

	return (
		<Modal
			className="createProductPopup"
			open={opened}
			onOk={onCreate}
			afterClose={closeWithReset}
			okButtonProps={{ htmlType: 'submit' }}
			onCancel={onCancel}
			okText="Create listing"
			centered
			closable={false}
			title={'Create Listing'}
			footer={null}
			destroyOnClose
		>
			{listingCollection === categoriesTitle.pupForShowCase ? (
				<Form
					initialValues={{
						categories: categoryId
					}}
					className="createProductForm"
					onFinish={onCreate}
				>
					<Form.Item name="pupName">
						<Input placeholder="Dog Name" />
					</Form.Item>
					<Form.Item name="images">
						<Typography.Paragraph>Images</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.images ? fileList.images : []}
							onPreview={handlePreview}
							onChange={handleChange}
							onRemove={handleRemoveImage}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setprofilePhotoURLs((pre) => [...pre, { url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item className="createProductCta">
						<Space className="createProductCtaSpace" align="center">
							{!isLoading && <Button onClick={onCancel}>Cancel</Button>}
							<Button
								disabled={isLoading || isUploading}
								loading={isLoading || isUploading}
								type="primary"
								htmlType="submit"
							>
								{isLoading ? `Creating Listing...` : `Create Listing`}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			) : listingCollection === categoriesTitle.pupProfile ? (
				<Form
					initialValues={{
						categories: categoryId
					}}
					className="createProductForm"
					onFinish={onCreate}
				>
					<Form.Item name="name">
						<Input placeholder="Enter Dog Name" />
					</Form.Item>
					<Form.Item name="dogType" label="">
  					<BreedSelect />
					</Form.Item>
					<Form.Item name="microChipNumber">
						<Input placeholder="Microchip Number" />
					</Form.Item>
					<Form.Item name="sourcenumber">
						<Input placeholder="Source Number (only if you're from VIC)" />
					</Form.Item>
					<Form.Item name="dogAge">
						<InputNumber precision={0} placeholder="Age" />
					</Form.Item>
					<Form.Item label="Age type">
						<Select defaultValue="Weeks or Years" value={ageType} onChange={(value) => setageType(value)}>
							<Select.Option value="Weeks">Weeks</Select.Option>
							<Select.Option value="Years">Years</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="dogPrice">
						<InputNumber className="productPricing" precision={2} placeholder="Price" />
					</Form.Item>
					{/* <Form.Item name="Collection">
						<Select
							placeholder="Type"
							key={selectedCollection}
							options={[
								{ value: 3, label: 'Pup For Showcase', key: 'PupForShowCase' },
								{ value: 2, label: 'Pups For Sale', key: 'pupProfile' },
								{ value: 1, label: 'Stud Profile', key: 'studProfile' },
								{ value: 4, label: 'Upcoming Litters', key: 'UpcomingLitters' }
							]}
						/>
					</Form.Item> */}
					<Form.Item name="aboutDog">
						<Input.TextArea placeholder="Tell us about your listing" rows={5} />
					</Form.Item>
					<Form.Item name="images">
						<Typography.Paragraph>Images</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.images ? fileList.images : []}
							onPreview={handlePreview}
							onChange={handleChange}
							onRemove={handleRemoveImage}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setprofilePhotoURLs((pre) => [...pre, { url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="pedigreePaperSire">
						<Typography.Paragraph>Pedigree Paper Sire</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.pedigreePaperSire ? fileList.pedigreePaperSire : []}
							onPreview={handlePreview}
							onChange={handleChangepedigreePaperSire}
							onRemove={handleRemovepedigreePaperSire}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setpedigreePaperSire(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="pedigreePaperDam">
						<Typography.Paragraph>Pedigree Paper Dam</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.pedigreePaperDam ? fileList.pedigreePaperDam : []}
							onPreview={handlePreview}
							onChange={handleChangepedigreePaperDam}
							onRemove={handleRemovepedigreePaperDam}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setpedigreePaperDam(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="vetChecks">
						<Typography.Paragraph>vetChecks</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.vetChecks ? fileList.vetChecks : []}
							onPreview={handlePreview}
							onChange={handleChangevetChecks}
							onRemove={handleRemovevetChecks}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setvetChecks(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="vaccinationRecords">
						<Typography.Paragraph>Vaccination Records</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.vaccinationRecords ? fileList.vaccinationRecords : []}
							onPreview={handlePreview}
							onChange={handleChangevaccinationRecords}
							onRemove={handleRemovevaccinationRecords}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setvaccinationRecords(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="pupvideo">
						<Typography.Paragraph>Pup video</Typography.Paragraph>
						<Upload
							name="files"
							accept="*"
							listType="picture-card"
							fileList={fileList?.pupvideo ? fileList.pupvideo : []}
							onPreview={handlePreview}
							onChange={handleChangePupvideo}
							onRemove={handleRemovePupvideo}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setPupvideo(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>

					{/* <Form.Item name="documents">
					<Typography.Paragraph>Documents</Typography.Paragraph>
					<Upload
						name='files'
						method='POST'
						accept='application/pdf'
						headers={{
							Authorization: `${BEARER} ${getToken()}`
						}}
						action={`${API_URL}/upload`}
						fileList={fileDocumentList}
						onChange={handleDocumentChange}
						onRemove={handleRemoveDocument}
					>
						<Button icon={<i className="ri-upload-cloud-line"></i>}>Upload</Button>
					</Upload>
				</Form.Item> */}
					<Form.Item className="createProductCta">
						<Space className="createProductCtaSpace" align="center">
							{!isLoading && <Button onClick={onCancel}>Cancel</Button>}
							<Button
								disabled={isLoading || isUploading}
								loading={isLoading || isUploading}
								type="primary"
								htmlType="submit"
							>
								{isLoading ? `Creating Listing...` : `Create Listing`}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			) : listingCollection === categoriesTitle.studProfile ? (
				<Form
					initialValues={{
						categories: categoryId
					}}
					className="createProductForm"
					onFinish={onCreate}
				>
					<Form.Item name="name">
						<Input placeholder="Name" />
					</Form.Item>
					<Form.Item name="dogType" label="">
  					<BreedSelect />
					</Form.Item>
					<Form.Item name="aboutDog">
						<Input.TextArea placeholder="Tell us about your listing" rows={5} />
					</Form.Item>
					<Form.Item label="Is Frozen Semen Available?">
						<Radio.Group onChange={(e) => setIsFrozenAvailable(e.target.value)} value={isFrozenAvailable}>
							<Radio value={true}>Yes</Radio>
							<Radio value={false}>No</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item name="images">
						<Typography.Paragraph>Images</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.images ? fileList.images : []}
							onPreview={handlePreview}
							onChange={handleChange}
							onRemove={handleRemoveImage}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setprofilePhotoURLs((pre) => [...pre, { url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="pedigreePaper">
						<Typography.Paragraph>pedigreePaper</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.pedigreePaper ? fileList.pedigreePaper : []}
							onPreview={handlePreview}
							onChange={handleChangePedigreePaper}
							onRemove={handleRemovePedigreePaper}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setPedigreePaper(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="vetChecks">
						<Typography.Paragraph>vetChecks</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.vetChecks ? fileList.vetChecks : []}
							onPreview={handlePreview}
							onChange={handleChangevetChecks}
							onRemove={handleRemovevetChecks}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setvetChecks(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="vaccinationRecords">
						<Typography.Paragraph>Vaccination Records</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.vaccinationRecords ? fileList.vaccinationRecords : []}
							onPreview={handlePreview}
							onChange={handleChangevaccinationRecords}
							onRemove={handleRemovevaccinationRecords}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setvaccinationRecords(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="semenAnalysis">
						<Typography.Paragraph>Semen Analysis</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.semenAnalysis ? fileList.semenAnalysis : []}
							onPreview={handlePreview}
							onChange={handleChangesemenAnalysis}
							onRemove={handleRemovesemenAnalysis}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setSemenAnalysis(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>

					{/* <Form.Item name="documents">
					<Typography.Paragraph>Documents</Typography.Paragraph>
					<Upload
						name='files'
						method='POST'
						accept='application/pdf'
						headers={{
							Authorization: `${BEARER} ${getToken()}`
						}}
						action={`${API_URL}/upload`}
						fileList={fileDocumentList}
						onChange={handleDocumentChange}
						onRemove={handleRemoveDocument}
					>
						<Button icon={<i className="ri-upload-cloud-line"></i>}>Upload</Button>
					</Upload>
				</Form.Item> */}
					<Form.Item className="createProductCta">
						<Space className="createProductCtaSpace" align="center">
							{!isLoading && <Button onClick={onCancel}>Cancel</Button>}
							<Button
								disabled={isLoading || isUploading}
								loading={isLoading || isUploading}
								type="primary"
								htmlType="submit"
							>
								{isLoading ? `Creating Listing...` : `Create Listing`}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			) : listingCollection === categoriesTitle.upcomingLitters ? (
				<Form
					initialValues={{
						categories: categoryId
					}}
					className="createProductForm"
					onFinish={onCreate}
				>
					<Form.Item name="dogType" label="">
  					<BreedSelect />
					</Form.Item>
					<Form.Item name="description">
						<Input.TextArea placeholder="Tell us about your listing" rows={5} />
					</Form.Item>
					<Form.Item name="pedigreePaperSire">
						<Typography.Paragraph>Pedigree Paper Sire</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.pedigreePaperSire ? fileList.pedigreePaperSire : []}
							onPreview={handlePreview}
							onChange={handleChangepedigreePaperSire}
							onRemove={handleRemovepedigreePaperSire}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setpedigreePaperSire(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="pedigreePaperDam">
						<Typography.Paragraph>Pedigree Paper Dam</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.pedigreePaperDam ? fileList.pedigreePaperDam : []}
							onPreview={handlePreview}
							onChange={handleChangepedigreePaperDam}
							onRemove={handleRemovepedigreePaperDam}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setpedigreePaperDam(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="mumImage">
						<Typography.Paragraph>Mum image</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.mumImage ? fileList.mumImage : []}
							onPreview={handlePreview}
							onChange={handleChangemumImage}
							onRemove={handleRemovemumImage}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setMumImage(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item name="dadImage">
						<Typography.Paragraph>Dad image</Typography.Paragraph>
						<Upload
							name="files"
							accept="image/*"
							listType="picture-card"
							fileList={fileList?.dadImage ? fileList.dadImage : []}
							onPreview={handlePreview}
							onChange={handleChangedadImage}
							onRemove={handleRemovedadImage}
							customRequest={async (e) => {
								const file: any = e.file;
								const onSuccess: any = e.onSuccess;
								const onError: any = e.onError;
								try {
									const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
									const task = uploadBytesResumable(storageRef, file);
									await task.then();
									const imageUrl = await getDownloadURL(task.snapshot.ref);
									setdadImage(() => [{ url: imageUrl, name: file.name }]);
									message.success(`${file.name} file uploaded successfully`);
									if (typeof onSuccess === 'function') onSuccess();
								} catch (error) {
									console.error(`${file.name} file upload failed:`, error);
									message.error(`${file.name} file upload failed.`);
									if (typeof onError === 'function') onError();
								}
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</Form.Item>
					<Form.Item className="createProductCta">
						<Space className="createProductCtaSpace" align="center">
							{!isLoading && <Button onClick={onCancel}>Cancel</Button>}
							<Button
								disabled={isLoading || isUploading}
								loading={isLoading || isUploading}
								type="primary"
								htmlType="submit"
							>
								{isLoading ? `Creating Listing...` : `Create Listing`}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			) : (
				''
			)}

			<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</Modal>
	);
};

export default CreateProductPopup;
