import React, { useEffect, useState } from 'react';
import './Profilepage.scss';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Badge, Col, Menu, Row, message, Button } from 'antd';
import type { MenuProps } from 'antd/es/menu';
import SettingsView from '../../views/profile/SettingsView';
import HelpView from '../../views/profile/HelpView';
import NotificationView from '../../views/profile/NotificationView';
import OrdersView from '../../views/profile/OrdersView';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../../utils/authHelpers';
import PrivacyPolicyView from '../../views/profile/PrivacyPolicyView';
import TermsOfServiceView from '../../views/profile/TermsOfServiceView';
import { Model } from '../../models/model';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useGetUser } from '../../utils/useGetUser';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
	label: React.ReactNode,
	key?: React.Key | null,
	icon?: React.ReactNode,
	children?: MenuItem[]
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
	} as MenuItem;
}

const Profilepage: React.FC = () => {
	const { user }: any = useGetUser();
	const [selectedView, setSelectedView] = useState<number>(0);
	const [notificationCount, setNotificationCount] = useState<number>(0);
	const [listOfNotifications, setListOfNotifications] = useState<Model.Notification[]>([]);
	const [forceUpdate, setForceUpdate] = useState<boolean>(false);
	const navigate = useNavigate();

	const logout = async () => {
		try {
			removeToken('profile page');
			navigate(`/login`);
			message.success(`Successfully logged out.`);
		} catch (error) {
			message.error(`Something went wrong while logging out.`);
		}
	};

	// Handle Stripe portal redirection with prefilled email
	const redirectToStripePortal = () => {
		const customerEmail = user?.email;

		if (!customerEmail) {
			message.error('User email is missing. Please contact support.');
			return;
		}

		// Dynamic Stripe Login Link with prefilled email
		const stripePortalUrl = `https://billing.stripe.com/p/login/cN2eX8dZU8e313y4gg?prefilled_email=${encodeURIComponent(
			customerEmail
		)}`;

		// Open the Stripe portal in a new tab
		window.open(stripePortalUrl, '_blank');
	};

	const items: MenuItem[] = [
		getItem('Settings', 0, <i className="ri-settings-2-line"></i>),
		getItem(
			<>
				Notification <Badge className="notificationCountBadge" count={notificationCount} />
			</>,
			1,
			<i className="ri-notification-3-line"></i>
		),
		getItem('My Orders', 2, <i className="ri-file-list-line"></i>),
		getItem('Help', 3, <i className="ri-question-line"></i>),
		getItem('Privacy Policy', 4, <i className="ri-shield-check-line"></i>),
		getItem('Terms of Service', 5, <i className="ri-pages-line"></i>),
		getItem('Logout', 6, <i className="ri-logout-box-line"></i>),
	];

	const listOfViews = [
		<SettingsView />,
		<NotificationView
			listOfNotifications={listOfNotifications}
			setListOfNotifications={(value: any) => setListOfNotifications(value)}
			setCount={(count: number) => setNotificationCount(notificationCount - count)}
			onForceCb={() => setForceUpdate(!forceUpdate)}
		/>,
		<OrdersView />,
		<HelpView />,
		<PrivacyPolicyView />,
		<TermsOfServiceView />,
	];

	useEffect(() => {
		if (!user?.uid) return;

		const orderRef = collection(db, 'Notifications');
		const q = query(orderRef, where('uid', '==', user.uid));

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			try {
				const tmp: any = [];
				querySnapshot.forEach((doc) => {
					tmp.push({ ...doc.data(), id: doc.id });
				});

				setListOfNotifications(tmp);
				setNotificationCount(tmp.filter((data: any) => !data?.isRead).length);
			} catch (error) {
				message.error(`Something went wrong while fetching notifications.`);
			}
		});
		return () => {
			unsubscribe();
		};
	}, [forceUpdate, user]);

	return (
		<PrivateLayout className="profilePage customLayoutWidth">
			<PageTitle title="My Profile" />
			<Row className="profileViewRow" gutter={[24, 24]}>
				<Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
					<Menu
						style={{ width: 256 }}
						onSelect={(e: any) => {
							if (e.key === '6') {
								logout();
								return;
							} else if (e.key === '2') {
								navigate(`/orders`);
							} else if (e.key === '4') {
								navigate(`/privacy-policy`);
							} else if (e.key === '5') {
								navigate(`/terms-and-conditions`);
							} else {
								setSelectedView(e.key);
							}
						}}
						defaultSelectedKeys={[selectedView.toString()]}
						mode={'vertical'}
						theme={'light'}
						items={items}
					/>
				</Col>
				<Col xs={24} sm={24} md={16} lg={20} xl={20} xxl={20}>
					{/* Only show subscription info if the user is NOT a buyer */}
					{!user?.isBuyer && (
						<div className="subscription-info">
							<h3>Subscription Management</h3>
							<p>Click the button below to manage your subscription through Stripe.</p>
							<Button type="primary" onClick={redirectToStripePortal}>
								Manage Subscription
							</Button>
						</div>
					)}
					{listOfViews[selectedView]}
				</Col>
			</Row>
		</PrivateLayout>
	);
};

export default Profilepage;
