import React, { useEffect, useState } from 'react';
import { PopupModel } from '../../models/PopupModel';
import './EditProductPopup.scss';
import { Button, Form, Input, InputNumber, Modal, Select, Space, Typography, message, App, Radio } from 'antd';
import { categories, categoriesTitle } from '../../utils/constant';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { Api } from '../../models/api';
import { db, storage } from '../../utils/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { doc, updateDoc, getDocs, collection } from 'firebase/firestore';
import geohash from 'ngeohash';

// Define comprehensive interfaces
interface FormValues {
  // PupForShowCase
  pupName?: string;
  // PupProfile
  aboutDog?: string;
  dogAge?: number;
  dogPrice?: number;
  dogType?: string;
  microChipNumber?: string;
  sourcenumber?: string;
  // StudProfile
  name?: string;
  bio?: string;
  type?: string;
  // UpcomingLitters
  description?: string;
}

interface FileListType {
  pupsImages: any;
  images: UploadFile[];
  vetChecks: UploadFile[];
  vetcheck: UploadFile[];
  vaccinationRecords: UploadFile[];
  vaccinationrecord: UploadFile[];
  pedigreePaperSire: UploadFile[];
  pedigreePaperDam: UploadFile[];
  pedigreepapersire: UploadFile[];
  pedigreepaperdam: UploadFile[];
  pedigreePaper: UploadFile[];
  pupvideo: UploadFile[];
  mumImage: UploadFile[];
  dadImage: UploadFile[];
  semenAnalysis: UploadFile[];
}

interface EditProductPopupProps extends PopupModel {
  animal: Api.Animal.Res.AnimalListing;
  categoryId: number;
}

interface Breed {
  id: string;
  name: string;
}

// Define types for update data
interface UpdateData {
  [key: string]: any;
  pupName?: string;
  pupImages?: string[];
  profilePhotoURLs?: string[];
  pupsImages?: string[] | string;
  dynamicLink?: string;
  uid?: string;
  location?: string;
  lat?: number;
  lng?: number;
  geohash?: string;
  isSold?: boolean;
  isSubscribed?: boolean;
  isPayoutVerified?: boolean;
  aboutDog?: string;
  ageType?: string;
  dogAge?: number;
  dogPrice?: number;
  dogType?: string;
  microChipNumber?: string;
  sourcenumber?: string;
  name?: string;
  type?: string;
  bio?: string;
  isFrozenAvailable?: boolean | null;
  pedigreePaper?: string | null;
  semenAnalysis?: string | null;
  vaccinationRecords?: string | null;
  vetChecks?: string | null;
  description?: string;
  mumImage?: string | null;
  dadImage?: string | null;
  pedigreePaperSire?: string | null;
  pedigreePaperDam?: string | null;
  vetcheck?: string | null;
  vaccinationrecord?: string | null;
  pedigreepapersire?: string | null;
  pedigreepaperdam?: string | null;
}

// Define or update AnimalListing interface
interface AnimalListing {
  // Basic fields
  id?: string;
  // ... other common fields ...

  // Uppercase versions
  vetChecks?: string;
  vaccinationRecords?: string;
  pedigreePaperSire?: string;
  pedigreePaperDam?: string;

  // Lowercase versions
  vetcheck?: string;
  vaccinationrecord?: string;
  pedigreepapersire?: string | null;
  pedigreepaperdam?: string | null;
  

}

// Helper function for base64 conversion
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

// Breeds hook
const useBreeds = () => {
  const [breeds, setBreeds] = useState<Breed[]>([]);
  const [loadingBreeds, setLoadingBreeds] = useState(false);

  const fetchBreeds = async () => {
    setLoadingBreeds(true);
    try {
      const breedsRef = collection(db, 'DogList');
      const querySnapshot = await getDocs(breedsRef);
      const breedsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name || ''
      }));
      setBreeds(breedsData);
    } catch (error) {
      console.error('Error fetching breeds:', error);
      message.error('Failed to load dog breeds');
    } finally {
      setLoadingBreeds(false);
    }
  };

  useEffect(() => {
    fetchBreeds();
  }, []);

  return { breeds, loadingBreeds };
};

// BreedSelect component
const BreedSelect = ({ value, onChange }: { value?: string; onChange?: (value: string) => void }) => {
  const { breeds, loadingBreeds } = useBreeds();

  return (
    <Select
      showSearch
      placeholder="Select Dog Breed"
      optionFilterProp="children"
      loading={loadingBreeds}
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={breeds.map((breed) => ({
        value: breed.name,
        label: breed.name,
      }))}
    />
  );
};

const EditProductPopup: React.FC<EditProductPopupProps> = ({ opened, onCancel, onForceCb, animal, categoryId }) => {
  const [form] = Form.useForm<FormValues>();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [fileList, setFileList] = useState<FileListType>({
    images: [],
    pupsImages: [],
    vetChecks: [],
    vetcheck: [],
    vaccinationRecords: [],
    vaccinationrecord: [],
    pedigreePaperSire: [],
    pedigreePaperDam: [],
    pedigreepapersire: [],
    pedigreepaperdam: [],
    pedigreePaper: [],
    pupvideo: [],
    mumImage: [],
    dadImage: [],
    semenAnalysis: []
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [profilePhotoURLs, setprofilePhotoURLs] = useState<Array<{ url: string }>>([]);
  const [vetChecks, setvetChecks] = useState<Array<{ url: string }>>([]);
  const [vetcheck, setvetcheck] = useState<Array<{ url: string }>>([]);
  const [vaccinationrecord, setvaccinationrecord] = useState<Array<{ url: string }>>([]);
  const [vaccinationRecords, setvaccinationRecords] = useState<Array<{ url: string }>>([]);
  const [pedigreePaperSire, setpedigreePaperSire] = useState<Array<{ url: string }>>([]);
  const [pedigreePaperDam, setpedigreePaperDam] = useState<Array<{ url: string }>>([]);
  const [pedigreepapersire, setpedigreepapersire] = useState<Array<{ url: string }>>([]);
  const [pedigreepaperdam, setpedigreepaperdam] = useState<Array<{ url: string }>>([]);
  const [pedigreePaper, setPedigreePaper] = useState<Array<{ url: string }>>([]);
  const [pupvideo, setPupvideo] = useState<Array<{ url: string }>>([]);
  const [mumImage, setMumImage] = useState<Array<{ url: string }>>([]);
  const [dadImage, setdadImage] = useState<Array<{ url: string }>>([]);
  const [semenAnalysis, setSemenAnalysis] = useState<Array<{ url: string }>>([]);
  const [ageType, setageType] = useState<string>(animal?.ageType || '');
  const [listingCollection, setListingCollection] = useState<string>('');
  const [isFrozenAvailable, setIsFrozenAvailable] = useState<boolean | null>(null);
  const [pupsImages, setpupsImages] = useState<Array<{ url: string }>>([]);

  // Collection effect
  useEffect(() => {
    if (categoryId) {
      const tmp = categories.find((item) => item.id === categoryId)?.collection;
      if (tmp) setListingCollection(tmp);
    }
  }, [categoryId]);

// Form initialization effect
useEffect(() => {
  if (animal && opened) {
    const formValues: FormValues = {};

    if (listingCollection === categoriesTitle.pupForShowCase) {
      formValues.pupName = animal.pupName;
    } else if (listingCollection === categoriesTitle.pupProfile) {
      formValues.dogType = animal.dogType;
      formValues.dogPrice = animal.dogPrice;
      formValues.dogAge = animal.dogAge;
      formValues.microChipNumber = animal.microChipNumber;
      formValues.sourcenumber = animal.sourcenumber;
      formValues.aboutDog = animal.aboutDog;
    } else if (listingCollection === categoriesTitle.studProfile) {
      formValues.name = animal.name;
      formValues.type = animal.type;
      formValues.bio = animal.bio;
    } else if (listingCollection === categoriesTitle.upcomingLitters) {
      formValues.dogType = animal.dogType;
      formValues.description = animal.description;
    }

    form.setFieldsValue(formValues);
    setageType(animal.ageType || '');
    setIsFrozenAvailable(animal.isFrozenAvailable || null);

    // Handle image initialization
    if (Array.isArray(animal.profilePhotoURLs) || Array.isArray(animal.pupsImages) || Array.isArray(animal.pupImages)) {
      const imageUrls = (() => {
        if (listingCollection === categoriesTitle.studProfile) {
          return animal.pupsImages || [];
        } else if (listingCollection === categoriesTitle.pupProfile) {
          return animal.profilePhotoURLs || [];
        } else {
          return animal.pupImages || [];
        }
      })();
      

      if (imageUrls.length > 0) {
        const formattedImages = imageUrls.map((url, index) => ({
          uid: `-${index}`,
          name: `image-${index}`,
          status: 'done',
          url: url
        })) as UploadFile<any>[];
        
        setFileList(prev => ({
          ...prev,
          images: formattedImages
        }));
        setprofilePhotoURLs(imageUrls.map(url => ({ url })));
      }
    }

    // Initialize files based on collection type
    if (listingCollection === categoriesTitle.pupProfile) {
      if (animal.vetcheck) {
        setvetcheck([{ url: animal.vetcheck }]);
        setFileList(prev => ({
          ...prev,
          vetcheck: [{ uid: '-1', name: 'vet-check', status: 'done', url: animal.vetcheck }]
        }));
      }
    
      if (animal.vaccinationrecord) {
        setvaccinationrecord([{ url: animal.vaccinationrecord }]);
        setFileList(prev => ({
          ...prev,
          vaccinationrecord: [{ uid: '-1', name: 'vaccination-record', status: 'done', url: animal.vaccinationrecord }]
        }));
      }
    
      if (animal.pedigreepapersire) {
        setpedigreepapersire([{ url: animal.pedigreepapersire }]);
        setFileList(prev => ({
          ...prev,
          pedigreepapersire: [{ uid: '-1', name: 'pedigree-sire', status: 'done', url: animal.pedigreepapersire }]
        }));
      }
    
      if (animal.pedigreepaperdam) {  // Changed from papersdam to paperdam
        setpedigreepaperdam([{ url: animal.pedigreepaperdam }]);
        setFileList(prev => ({
          ...prev,
          pedigreepaperdam: [{ uid: '-1', name: 'pedigree-dam', status: 'done', url: animal.pedigreepaperdam }]
        }));
      }
      if (animal.pupvideo) {
        console.log('Initializing pupvideo:', animal.pupvideo);
        setPupvideo([{ url: animal.pupvideo }]);
        setFileList(prev => ({
          ...prev,
          pupvideo: [{
            uid: '-1',
            name: 'pupvideo',
            status: 'done',
            url: animal.pupvideo
          }]
        }));
      }
    } else if (listingCollection === categoriesTitle.studProfile) {
      // CamelCase versions for studProfile

      if (Array.isArray(animal.pupsImages)) {
        const formattedImages = animal.pupsImages.map((url, index) => ({
          uid: `-${index}`,
          name: `pups-images-${index}`,
          status: 'done' as const,
          url: url
        }));
    
        setpupsImages(animal.pupsImages.map(url => ({ url })));
        setFileList(prev => ({
          ...prev,
          pupsImages: formattedImages
        }));
      }

      if (animal.vetChecks) {
        setvetChecks([{ url: animal.vetChecks }]);
        setFileList(prev => ({
          ...prev,
          vetChecks: [{ uid: '-1', name: 'vet-checks', status: 'done', url: animal.vetChecks }]
        }));
      }

      if (animal.vaccinationRecords) {
        setvaccinationRecords([{ url: animal.vaccinationRecords }]);
        setFileList(prev => ({
          ...prev,
          vaccinationRecords: [{ uid: '-1', name: 'vaccination-records', status: 'done', url: animal.vaccinationRecords }]
        }));
      }

      if (animal.pedigreePaper) {
        setPedigreePaper([{ url: animal.pedigreePaper }]);
        setFileList(prev => ({
          ...prev,
          pedigreePaper: [{ uid: '-1', name: 'pedigree-paper', status: 'done', url: animal.pedigreePaper }]
        }));
      }

      if (animal.semenAnalysis) {
        setSemenAnalysis([{ url: animal.semenAnalysis }]);
        setFileList(prev => ({
          ...prev,
          semenAnalysis: [{ uid: '-1', name: 'semen-analysis', status: 'done', url: animal.semenAnalysis }]
        }));
      }
    } else if (listingCollection === categoriesTitle.upcomingLitters) {
      // CamelCase versions for upcomingLitters
      if (animal.pedigreePaperDam) {
        setpedigreePaperDam([{ url: animal.pedigreePaperDam }]);
        setFileList(prev => ({
          ...prev,
          pedigreePaperDam: [{ uid: '-1', name: 'pedigree-paper-dam', status: 'done', url: animal.pedigreePaperDam }]
        }));
      }

      if (animal.pedigreePaperSire) {
        setpedigreePaperSire([{ url: animal.pedigreePaperSire }]);
        setFileList(prev => ({
          ...prev,
          pedigreePaperSire: [{ uid: '-1', name: 'pedigree-paper-sire', status: 'done', url: animal.pedigreePaperSire }]
        }));
      }

      if (animal.mumImage) {
        setMumImage([{ url: animal.mumImage }]);
        setFileList(prev => ({
          ...prev,
          mumImage: [{ uid: '-1', name: 'mum-image', status: 'done', url: animal.mumImage }]
        }));
      }

      if (animal.dadImage) {
        setdadImage([{ url: animal.dadImage }]);
        setFileList(prev => ({
          ...prev,
          dadImage: [{ uid: '-1', name: 'dad-image', status: 'done', url: animal.dadImage }]
        }));
        
      }
    }
  }
}, [opened, animal, form, categoryId, listingCollection]);

  // Preview handlers
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  // Main images handler
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, images: newFileList }));
  };

  const handleRemoveImage = async (file: UploadFile) => {
    const filteredImagesUrl = profilePhotoURLs.filter((data) => data.url !== file.url);
    setprofilePhotoURLs(filteredImagesUrl);
    setFileList(prev => ({
      ...prev,
      images: prev.images.filter(f => f.url !== file.url)
    }));
  };

  const handleChangepupsImages: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, pupsImages: newFileList }));
  };
  
  const handleRemovepupsImages = async (file: UploadFile) => {
    const filteredImagesUrl = profilePhotoURLs.filter((data) => data.url !== file.url);
    setprofilePhotoURLs(filteredImagesUrl);
    setFileList(prev => ({
      ...prev,
      pupsImages: prev.pupsImages.filter((f: { url: string | undefined; }) => f.url !== file.url)
    }));
  };
  
  // Handler pairs for each file type
  const handleChangedadImage: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, dadImage: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };

  const handleRemovedadImage = () => {
    setdadImage([]);
    setFileList(prev => ({ ...prev, dadImage: [] }));
    setIsUploading(false);
  };

  const handleChangemumImage: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, mumImage: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };

  const handleRemovemumImage = () => {
    setMumImage([]);
    setFileList(prev => ({ ...prev, mumImage: [] }));
    setIsUploading(false);
  };

  const handleChangepedigreePaperSire: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    }
    if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList((pre: any) => ({ 
      ...pre, 
      pedigreePaperSire: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
    }));
  };
  
  const handleRemovepedigreePaperSire = async () => {
    setpedigreePaperSire([]);
    setFileList((pre: any) => ({ ...pre, pedigreePaperSire: [] }));
    setIsUploading(false);
  };

  const handleChangepedigreePaperDam: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    }
    if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList((pre: any) => ({ 
      ...pre, 
      pedigreePaperDam: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
    }));
  };
  
  const handleRemovepedigreePaperDam = async () => {
    setpedigreePaperDam([]);
    setFileList((pre: any) => ({ ...pre, pedigreePaperDam: [] }));
    setIsUploading(false);
  };
  


  const handleChangepedigreepapersire: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    }
    if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList((pre: any) => ({ 
      ...pre, 
      pedigreepapersire: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
    }));
  };
  
  const handleRemovepedigreepapersire = async () => {
    setpedigreepapersire([]);
    setFileList((pre: any) => ({ ...pre, pedigreepapersire: [] }));
    setIsUploading(false);
  };

  const handleChangepedigreepaperdam: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    }
    if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList((pre: any) => ({ 
      ...pre, 
      pedigreepaperdam: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
    }));
  };
  
  const handleRemovepedigreepaperdam = async () => {
    setpedigreepaperdam([]);
    setFileList((pre: any) => ({ ...pre, pedigreepaperdam: [] }));
    setIsUploading(false);
  };

  // Critical onUpdate function
  const onUpdate = async (values: FormValues) => {
    try {
      if (!animal.id) {
        message.error('Animal ID is missing');
        return;
      }

      const currentCollection = categories.find((item) => item.id === categoryId)?.collection;
      if (!currentCollection) {
        message.error('Invalid category');
        return;
      }

      let updateData: UpdateData = {};

      if (currentCollection === categoriesTitle.pupForShowCase) {
        updateData = {
          pupName: values.pupName,
          pupImages: profilePhotoURLs.map(item => item.url).filter(Boolean),
          dynamicLink: animal.dynamicLink,
          uid: animal.uid,
          location: animal.location?.toString(), // Convert String object to primitive string
          lat: animal.lat,
          lng: animal.lng,
          geohash: (animal.geohash || geohash.encode(animal.lat || 0, animal.lng || 0))?.toString(), // Fixed
          isSold: animal.isSold,
          isSubscribed: animal.isSubscribed,
          isPayoutVerified: animal.isPayoutVerified
        };
      } else if (currentCollection === categoriesTitle.pupProfile) {
        updateData = {
          aboutDog: values.aboutDog,
          ageType,
          dogAge: values.dogAge,
          dogPrice: values.dogPrice,
          dogType: values.dogType,
          microChipNumber: values.microChipNumber,
          sourcenumber: values.sourcenumber,
          profilePhotoURLs: profilePhotoURLs.map(item => item.url).filter(Boolean),
          dynamicLink: animal.dynamicLink,
          uid: animal.uid,
          location: animal.location?.toString(), // Convert String object to primitive string
          lat: animal.lat,
          lng: animal.lng,
          geohash: (animal.geohash || geohash.encode(animal.lat || 0, animal.lng || 0))?.toString(), // Fixed
          isSold: animal.isSold,
          isSubscribed: animal.isSubscribed,
          isPayoutVerified: animal.isPayoutVerified,
          vetcheck: vetcheck?.[0]?.url || null,
          pupvideo: pupvideo?.[0]?.url || null,
          ...(pupvideo?.length === 0 && { pupvideo: null }),
          vaccinationrecord: vaccinationrecord?.[0]?.url || null,
          pedigreepapersire: pedigreepapersire?.[0]?.url || '', // For updating
          pedigreepaperdam: pedigreepaperdam?.[0]?.url || '', // For updating
              // Set to null when arrays are empty (for deletions)
              ...(pedigreepapersire?.length === 0 && { pedigreepapersire: '' }),
              ...(pedigreepaperdam?.length === 0 && { pedigreepaperdam: '' }),
          ...(vetcheck?.length === 0 && { vetcheck: '' }),
          ...(vaccinationrecord?.length === 0 && { vaccinationrecord: '' }),
          ...(pupvideo?.length === 0 && { pupvideo: '' })
        };
      } else if (currentCollection === categoriesTitle.studProfile) {
        updateData = {
          name: values.name,
          type: values.type,
          bio: values.bio,
          isFrozenAvailable,
          dynamicLink: animal.dynamicLink,
          uid: animal.uid,
          location: animal.location?.toString(),
          lat: animal.lat,
          lng: animal.lng,
          geohash: (animal.geohash || geohash.encode(animal.lat || 0, animal.lng || 0))?.toString(),
          isSold: animal.isSold,
          isSubscribed: animal.isSubscribed,
          isPayoutVerified: animal.isPayoutVerified,
      
          pupsImages: profilePhotoURLs.length > 0 
            ? profilePhotoURLs.map(item => item.url).filter(Boolean)
            : '',
          pedigreePaper: pedigreePaper?.[0]?.url || '',
          ...(pedigreePaper?.length === 0 && { pedigreePaper: '' }),
          semenAnalysis: semenAnalysis?.[0]?.url || '',
          ...(semenAnalysis?.length === 0 && { semenAnalysis: '' }),
          vaccinationRecords: vaccinationRecords?.[0]?.url || '',
          ...(vaccinationRecords?.length === 0 && { vaccinationRecords: '' }),
          vetChecks: vetChecks?.[0]?.url || '',
          ...(vetChecks?.length === 0 && { vetChecks: '' })
        };

      } else if (currentCollection === categoriesTitle.upcomingLitters) {
        updateData = {
          description: values.description,
          dogType: values.dogType,
          mumImage: mumImage?.[0]?.url || null,
          dadImage: dadImage?.[0]?.url || null,
          pedigreePaperSire: pedigreePaperSire?.[0]?.url || '',
          pedigreePaperDam: pedigreePaperDam?.[0]?.url || '',
          dynamicLink: animal.dynamicLink,
          uid: animal.uid,
          location: animal.location?.toString(), // Fixed
          lat: animal.lat,
          lng: animal.lng,
          geohash: (animal.geohash || geohash.encode(animal.lat || 0, animal.lng || 0))?.toString(), // Fixed
          isSold: animal.isSold,
          isSubscribed: animal.isSubscribed,
          isPayoutVerified: animal.isPayoutVerified,

          ...(mumImage?.length === 0 && { mumImage: null }),
          ...(dadImage?.length === 0 && { dadImage: null }),
          ...(pedigreePaperSire?.length === 0 && { pedigreePaperSire: null }),
          ...(pedigreePaperDam?.length === 0 && { pedigreePaperDam: null }),
          ...(pedigreePaperSire?.length === 0 && { pedigreePaperSire: '' }),
          ...(pedigreePaperDam?.length === 0 && { pedigreePaperDam: '' })
        };
      }
      console.log('Update data:', updateData);

      // Clean up undefined/null values
      Object.keys(updateData).forEach(key => {
        if (updateData[key] === undefined || updateData[key] === null) {
          delete updateData[key];
        }
      });

      const docRef = doc(db, currentCollection, animal.id);
      await updateDoc(docRef, updateData);

      message.success('Listing updated successfully');
      onCancel();
      onForceCb();
    } catch (error) {
      console.error('Error updating document:', error);
      message.error('Failed to update listing');
    }
  };

  const handleChangesemenAnalysis: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, semenAnalysis: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };

  const handleRemovesemenAnalysis = () => {
    setSemenAnalysis([]);
    setFileList(prev => ({ ...prev, semenAnalysis: [] }));
    setIsUploading(false);
  };

  const handleChangevaccinationRecords: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, vaccinationRecords: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };

  const handleRemovevaccinationRecords = () => {
    setvaccinationRecords([]);
    setFileList(prev => ({ ...prev, vaccinationRecords: [] }));
    setIsUploading(false);
  };

  const handleChangevaccinationrecord: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, vaccinationRecords: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };

  const handleRemovevaccinationrecord = () => {
    setvaccinationrecord([]);
    setFileList(prev => ({ ...prev, vaccinationrecord: [] }));
    setIsUploading(false);
  };

  const handleChangevetChecks: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, vetChecks: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };

  const handleRemovevetChecks = () => {
    setvetChecks([]);
    setFileList(prev => ({ ...prev, vetChecks: [] }));
    setIsUploading(false);
  };

  const handleChangevetcheck: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, vetcheck: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };

  const handleRemovevetcheck = () => {
    setvetcheck([]);
    setFileList(prev => ({ ...prev, vetcheck: [] }));
    setIsUploading(false);
  };

  const handleChangePedigreePaper: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    } else if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ ...prev, pedigreePaper: newFileList.length > 0 ? [newFileList[newFileList.length - 1]] : [] }));
  };
  

  const handleRemovePedigreePaper = () => {
    setPedigreePaper([]);
    setFileList(prev => ({ ...prev, pedigreePaper: [] }));
    setIsUploading(false);
  };

  const closeWithReset = () => {
    setPreviewOpen(false);
    setPreviewImage('');
    setPreviewTitle('');
    setFileList({
      images: [],
      pupsImages: [],
      vetChecks: [],
      vetcheck: [],
      vaccinationRecords: [],
      vaccinationrecord: [],
      pedigreePaperSire: [],
      pedigreePaperDam: [],
      pedigreePaper: [],
      pedigreepapersire: [],
      pedigreepaperdam: [],
      pupvideo: [],
      mumImage: [],
      dadImage: [],
      semenAnalysis: []
    });
    setprofilePhotoURLs([]);
    setageType('');
    form.resetFields();
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChangePupvideo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setIsUploading(true);
    if (newFileList.length > 0 && newFileList[newFileList.length - 1].status === 'done') {
      setIsUploading(false);
    }
    if (newFileList.length === 0) {
      setIsUploading(false);
    }
    setFileList(prev => ({ 
      ...prev, 
      pupvideo: newFileList.length ? [newFileList[newFileList.length - 1]] : [] 
    }));
  };
  
  const handleRemovePupvideo = () => {
    setPupvideo([]);
    setFileList(prev => ({ ...prev, pupvideo: [] }));
    setIsUploading(false);
  };
  

  return (
    <App>
      <Modal
        className="editProductPopup"
        open={opened}
        onCancel={onCancel}
        afterClose={closeWithReset}
        centered
        closable={false}
        title="Edit Listing"
        footer={null}
        destroyOnClose
      >
        {listingCollection === categoriesTitle.pupForShowCase ? (
          <Form
            form={form}
            className="editProductForm"
            onFinish={onUpdate}
            initialValues={{ ...animal, categories: categoryId }}
          >
            <Form.Item name="pupName">
              <Input placeholder="Dog Name" />
            </Form.Item>

            <Form.Item name="images">
              <Typography.Paragraph>Images</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.images}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemoveImage}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setprofilePhotoURLs((pre) => [...pre, { url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.images.length >= 8 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item className="editProductCta">
              <Space className="editProductCtaSpace" align="center">
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Update Listing
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : listingCollection === categoriesTitle.pupProfile ? (
          <Form
            form={form}
            className="editProductForm"
            onFinish={onUpdate}
            initialValues={{ ...animal, categories: categoryId }}
          >
            <Form.Item name="dogType">
              <BreedSelect />
            </Form.Item>

            <Form.Item name="microChipNumber">
              <Input placeholder="Microchip Number" />
            </Form.Item>

            <Form.Item name="sourcenumber">
              <Input placeholder="Source Number (only if you're from VIC)" />
            </Form.Item>

            <Form.Item name="dogAge">
              <InputNumber precision={0} placeholder="Age" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Age type">
              <Select value={ageType} onChange={(value) => setageType(value)}>
                <Select.Option value="Weeks">Weeks</Select.Option>
                <Select.Option value="Years">Years</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="dogPrice">
              <InputNumber className="productPricing" precision={2} placeholder="Price" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name="aboutDog">
              <Input.TextArea placeholder="Tell us about your listing" rows={5} />
            </Form.Item>

            <Form.Item name="pupsImages">
  <Typography.Paragraph>Images</Typography.Paragraph>
  <Upload
    name="files"
    accept="image/*"
    listType="picture-card"
    fileList={fileList.pupsImages}
    onPreview={handlePreview}
    onChange={handleChange}
    onRemove={handleRemoveImage}
    customRequest={async (e) => {
      const file: any = e.file;
      const onSuccess: any = e.onSuccess;
      const onError: any = e.onError;
      try {
        const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
        const task = uploadBytesResumable(storageRef, file);
        await task.then();
        const imageUrl = await getDownloadURL(task.snapshot.ref);
        setprofilePhotoURLs((pre) => [...pre, { url: imageUrl, name: file.name }]);
        message.success(`${file.name} file uploaded successfully`);
        if (typeof onSuccess === 'function') onSuccess();
      } catch (error) {
        console.error(`${file.name} file upload failed:`, error);
        message.error(`${file.name} file upload failed.`);
        if (typeof onError === 'function') onError();
      }
    }}
  >
    {fileList.pupsImages.length >= 8 ? null : uploadButton}
  </Upload>
</Form.Item>

<Form.Item name="pedigreepapersire">
  <Typography.Paragraph>Pedigree Paper Sire</Typography.Paragraph>
  <Upload
    name="files"
    accept="image/*"
    listType="picture-card"
    fileList={fileList.pedigreepapersire}
    onPreview={handlePreview}
    onChange={handleChangepedigreepapersire}
    onRemove={handleRemovepedigreepapersire}
    customRequest={async (e) => {
      const file: any = e.file;
      const onSuccess: any = e.onSuccess;
      const onError: any = e.onError;
      try {
        const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
        const task = uploadBytesResumable(storageRef, file);
        await task.then();
        const imageUrl = await getDownloadURL(task.snapshot.ref);
        setpedigreepapersire(() => [{ url: imageUrl, name: file.name }]);
        message.success(`${file.name} file uploaded successfully`);
        if (typeof onSuccess === 'function') onSuccess();
      } catch (error) {
        console.error(`${file.name} file upload failed:`, error);
        message.error(`${file.name} file upload failed.`);
        if (typeof onError === 'function') onError();
      }
    }}
  >
    {fileList.pedigreepapersire.length >= 1 ? null : uploadButton}
  </Upload>
</Form.Item>

<Form.Item name="pedigreepaperdam">
  <Typography.Paragraph>Pedigree Paper Dam</Typography.Paragraph>
  <Upload
    name="files"
    accept="image/*"
    listType="picture-card"
    fileList={fileList.pedigreepaperdam}
    onPreview={handlePreview}
    onChange={handleChangepedigreepaperdam}
    onRemove={handleRemovepedigreepaperdam}
    customRequest={async (e) => {
      const file: any = e.file;
      const onSuccess: any = e.onSuccess;
      const onError: any = e.onError;
      try {
        const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
        const task = uploadBytesResumable(storageRef, file);
        await task.then();
        const imageUrl = await getDownloadURL(task.snapshot.ref);
        setpedigreepaperdam(() => [{ url: imageUrl, name: file.name }]);
        message.success(`${file.name} file uploaded successfully`);
        if (typeof onSuccess === 'function') onSuccess();
      } catch (error) {
        console.error(`${file.name} file upload failed:`, error);
        message.error(`${file.name} file upload failed.`);
        if (typeof onError === 'function') onError();
      }
    }}
  >
    {fileList.pedigreepaperdam.length >= 1 ? null : uploadButton}
  </Upload>
</Form.Item>

<Form.Item name="vetcheck">
  <Typography.Paragraph>Vet Checks</Typography.Paragraph>
  <Upload
    name="files"
    accept="image/*"
    listType="picture-card"
    fileList={fileList.vetcheck}
    onPreview={handlePreview}
    onChange={handleChangevetcheck}
    onRemove={handleRemovevetcheck}
    customRequest={async (e) => {
      const file: any = e.file;
      const onSuccess: any = e.onSuccess;
      const onError: any = e.onError;
      try {
        const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
        const task = uploadBytesResumable(storageRef, file);
        await task.then();
        const imageUrl = await getDownloadURL(task.snapshot.ref);
        setvetcheck(() => [{ url: imageUrl, name: file.name }]);
        message.success(`${file.name} file uploaded successfully`);
        if (typeof onSuccess === 'function') onSuccess();
      } catch (error) {
        console.error(`${file.name} file upload failed:`, error);
        message.error(`${file.name} file upload failed.`);
        if (typeof onError === 'function') onError();
      }
    }}
  >
    {fileList.vetcheck.length >= 1 ? null : uploadButton}
  </Upload>
</Form.Item>

<Form.Item name="vaccinationrecord">
  <Typography.Paragraph>Vaccination Records</Typography.Paragraph>
  <Upload
    name="files"
    accept="image/*"
    listType="picture-card"
    fileList={fileList.vaccinationrecord}
    onPreview={handlePreview}
    onChange={handleChangevaccinationrecord}
    onRemove={handleRemovevaccinationrecord}
    customRequest={async (e) => {
      const file: any = e.file;
      const onSuccess: any = e.onSuccess;
      const onError: any = e.onError;
      try {
        const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
        const task = uploadBytesResumable(storageRef, file);
        await task.then();
        const imageUrl = await getDownloadURL(task.snapshot.ref);
        setvaccinationrecord(() => [{ url: imageUrl, name: file.name }]);
        message.success(`${file.name} file uploaded successfully`);
        if (typeof onSuccess === 'function') onSuccess();
      } catch (error) {
        console.error(`${file.name} file upload failed:`, error);
        message.error(`${file.name} file upload failed.`);
        if (typeof onError === 'function') onError();
      }
    }}
  >
    {fileList.vaccinationRecords.length >= 1 ? null : uploadButton}
  </Upload>
</Form.Item>

<Form.Item name="pupvideo">
  <Typography.Paragraph>Pup video</Typography.Paragraph>
  <Upload
    name="files"
    accept="video/*"
    listType="picture-card"
    fileList={fileList.pupvideo}
    onPreview={handlePreview}
    onChange={handleChangePupvideo}
    onRemove={handleRemovePupvideo}
    customRequest={async (e) => {
      const file: any = e.file;
      const onSuccess: any = e.onSuccess;
      const onError: any = e.onError;
      try {
        const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
        const task = uploadBytesResumable(storageRef, file);
        await task.then();
        const imageUrl = await getDownloadURL(task.snapshot.ref);
        setPupvideo([{ url: imageUrl }]);  // Remove the name property here
        onSuccess?.();  // Call onSuccess before message
        message.success(`${file.name} file uploaded successfully`);
      } catch (error) {
        console.error(`${file.name} file upload failed:`, error);
        message.error(`${file.name} file upload failed.`);
        onError?.(error);
      }
    }}
  >
    {fileList.pupvideo?.length >= 1 ? null : uploadButton}
  </Upload>
</Form.Item>

            <Form.Item className="editProductCta">
              <Space className="editProductCtaSpace" align="center">
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Update Listing
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : listingCollection === categoriesTitle.studProfile ? (
          <Form
            form={form}
            className="editProductForm"
            onFinish={onUpdate}
            initialValues={{ ...animal, categories: categoryId }}
          >
            <Form.Item name="name">
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item name="type">
              <BreedSelect />
            </Form.Item>

            <Form.Item name="bio">
              <Input.TextArea placeholder="Tell us about your listing" rows={5} />
            </Form.Item>

            <Form.Item label="Is Frozen Semen Available?">
              <Radio.Group 
                onChange={(e) => setIsFrozenAvailable(e.target.value)} 
                value={isFrozenAvailable}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="pupsImages">
 <Typography.Paragraph>Images</Typography.Paragraph>
 <Upload
   name="files"
   accept="image/*"
   listType="picture-card"
   fileList={fileList.pupsImages}
   onPreview={handlePreview}
   onChange={handleChangepupsImages}
   onRemove={handleRemovepupsImages}
   customRequest={async (e) => {
     const file: any = e.file;
     const onSuccess: any = e.onSuccess;
     const onError: any = e.onError;
     try {
       const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
       const task = uploadBytesResumable(storageRef, file);
       await task.then();
       const imageUrl = await getDownloadURL(task.snapshot.ref);
       setprofilePhotoURLs((pre) => [...pre, { url: imageUrl, name: file.name }]);
       message.success(`${file.name} file uploaded successfully`);
       if (typeof onSuccess === 'function') onSuccess();
     } catch (error) {
       console.error(`${file.name} file upload failed:`, error);
       message.error(`${file.name} file upload failed.`);
       if (typeof onError === 'function') onError();
     }
   }}
 >
   {fileList.pupsImages.length >= 8 ? null : uploadButton}
 </Upload>
</Form.Item>

            <Form.Item name="pedigreePaper">
              <Typography.Paragraph>Pedigree Paper</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.pedigreePaper}
                onPreview={handlePreview}
                onChange={handleChangePedigreePaper}
                onRemove={handleRemovePedigreePaper}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setPedigreePaper(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.pedigreePaper.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item name="vetChecks">
              <Typography.Paragraph>Vet Checks</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.vetChecks}
                onPreview={handlePreview}
                onChange={handleChangevetChecks}
                onRemove={handleRemovevetChecks}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setvetChecks(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.vetChecks.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item name="vaccinationRecords">
              <Typography.Paragraph>Vaccination Records</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.vaccinationRecords}
                onPreview={handlePreview}
                onChange={handleChangevaccinationRecords}
                onRemove={handleRemovevaccinationRecords}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setvaccinationRecords(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.vaccinationRecords.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item name="semenAnalysis">
              <Typography.Paragraph>Semen Analysis</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.semenAnalysis}
                onPreview={handlePreview}
                onChange={handleChangesemenAnalysis}
                onRemove={handleRemovesemenAnalysis}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setSemenAnalysis(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.semenAnalysis.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item className="editProductCta">
              <Space className="editProductCtaSpace" align="center">
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Update Listing
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : listingCollection === categoriesTitle.upcomingLitters ? (
          <Form
            form={form}
            className="editProductForm"
            onFinish={onUpdate}
            initialValues={{ ...animal, categories: categoryId }}
          >
            <Form.Item name="dogType">
              <BreedSelect />
            </Form.Item>

            <Form.Item name="description">
              <Input.TextArea placeholder="Tell us about your listing" rows={5} />
            </Form.Item>

            <Form.Item name="pedigreePaperSire">
              <Typography.Paragraph>Pedigree Paper Sire</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.pedigreePaperSire}
                onPreview={handlePreview}
                onChange={handleChangepedigreePaperSire}
                onRemove={handleRemovepedigreePaperSire}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setpedigreePaperSire(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.pedigreePaperSire.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item name="pedigreePaperDam">
              <Typography.Paragraph>Pedigree Paper Dam</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.pedigreePaperDam}
                onPreview={handlePreview}
                onChange={handleChangepedigreePaperDam}
                onRemove={handleRemovepedigreePaperDam}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setpedigreePaperDam(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.pedigreePaperDam.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item name="mumImage">
              <Typography.Paragraph>Mum Image</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.mumImage}
                onPreview={handlePreview}
                onChange={handleChangemumImage}
                onRemove={handleRemovemumImage}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setMumImage(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.mumImage.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item name="dadImage">
              <Typography.Paragraph>Dad Image</Typography.Paragraph>
              <Upload
                name="files"
                accept="image/*"
                listType="picture-card"
                fileList={fileList.dadImage}
                onPreview={handlePreview}
                onChange={handleChangedadImage}
                onRemove={handleRemovedadImage}
                customRequest={async (e) => {
                  const file: any = e.file;
                  const onSuccess: any = e.onSuccess;
                  const onError: any = e.onError;
                  try {
                    const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
                    const task = uploadBytesResumable(storageRef, file);
                    await task.then();
                    const imageUrl = await getDownloadURL(task.snapshot.ref);
                    setdadImage(() => [{ url: imageUrl, name: file.name }]);
                    message.success(`${file.name} file uploaded successfully`);
                    if (typeof onSuccess === 'function') onSuccess();
                  } catch (error) {
                    console.error(`${file.name} file upload failed:`, error);
                    message.error(`${file.name} file upload failed.`);
                    if (typeof onError === 'function') onError();
                  }
                }}
              >
                {fileList.dadImage.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item className="editProductCta">
              <Space className="editProductCtaSpace" align="center">
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Update Listing
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : null}

        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Modal>
    </App>
  );
};

export default EditProductPopup;